export const sprtbr = require('../assets/img/sprtbr1.jpg');
export const sprtbe2 = require('../assets/img/sprtbr2.jpg');
export const fastfood1 = require('../assets/img/inner-pages-gallery-img-5.jpg');
export const fastfood2 = require('../assets/img/inner-pages-gallery-img-7.jpg');
export const gallery1 = require('../assets/img/gallery1.jpg');
export const gallery2 = require('../assets/img/gallery2.jpg');
export const gallery3 = require('../assets/img/gallery3.jpg');
export const gallery4 = require('../assets/img/gallery4.jpg');
export const gallery5 = require('../assets/img/gallery5.jpg');
export const gallery6 = require('../assets/img/gallery6.jpg');
export const gallery7 = require('../assets/img/gallery7.jpg');
export const gallery8 = require('../assets/img/gallery8.jpg');
export const logo = require('../assets/img/logo.png')
export const qutn = require('../assets/img/qutn.png');
export const cont3 = require('../assets/img/cont3.png');
export const cont2 = require('../assets/img/cont2.png');
export const cont1 = require('../assets/img/cont1.png');
export const abtbanner5 = require('../assets/img/abtbanner5.jpg')
export const chef1 = require('../assets/img/chef1.jpg')
export const chef2 = require('../assets/img/chef2.jpg');
export const abbanner1 = require('../assets/img/abbanner1.jpg');
export const abtbanner3 = require('../assets/img/abtbanner3.jpg');
export const veg = require('../assets/img/veg.png');
export const nonveg = require('../assets/img/nonveg.png');
export const redchili = require('../assets/img/redchili.png')
export const eveim2 = require('../assets/img/eveim2.jpg');
export const eveim1 = require('../assets/img/eveim1.jpg')
export const whtsn4 = require('../assets/img/whtsn4.jpg')
export const whtsn2 = require('../assets/img/whtsn2.jpg');
export const evngl1 = require('../assets/img/evngl1.jpg');
export const evngl2 = require('../assets/img/evngl2.jpg');
export const evngl3 = require('../assets/img/evngl3.jpg');
export const calender = require('../assets/img/even1.png');
export const popup = require('../assets/img/evntbnr.jpg')